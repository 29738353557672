import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { UndefinedInitialDataOptions } from '@tanstack/react-query/src/queryOptions';

import { BigNumber } from 'ethers';
import { Options } from 'ky';
import { Address } from 'viem';

import {
  getOneInchCrossChainQuote,
  getOneInchGasPrices,
  getOneInchNetworks,
  getOneInchSingleChainQuote,
  getOneInchTerms,
  getOneInchTokenAllowance,
  getOneInchTokens,
  getOneInchTxApprove,
  getTokenPriceInUSD,
  makeOneInchSingleChainTokensSwap,
  OneInchCrossChainQuote,
  OneInchNetwork,
  OneInchQuote,
  storeSwapTx,
} from '@api';

interface GetAlloawnceQueryKeys {
  networkId?: OneInchNetwork['id'];
  tokenAddress?: Address;
  walletAddress?: Address;
}

export const oneInchQueryKeys = {
  getNetworks: ['getOneInchNetworks'],
  getTokens: (networkId = -99999) => ['getOneInchTokens', networkId],
  getQuote: ({
    fromNetworkId,
    toNetworkId,
    fromTokenAddress,
    toTokenAddress,
    amount,
    walletAddress,
  }: UseGetOneInchQuoteQueryParams = {}) => [
    'getOneInchQuote',
    {
      fromNetworkId,
      toNetworkId,
      fromTokenAddress,
      toTokenAddress,
      amount: amount?.toString(),
      walletAddress,
    },
  ],
  getAllowance: (params: GetAlloawnceQueryKeys) => ['getOneInchAllowance', params],
  increaseAllowance: ['increaseOneInchAllowance'],
  storeSwapTx: ['storeSwapTx'],
  getOneInchGasPrices: ['getOneInchGasPrices'],
  getTokenPriceInUSD: (networkId = -99999, tokenAddress: Address = '0x00-0') => [
    'getTokenPriceInUSD',
    networkId,
    tokenAddress,
  ],
  getTerms: (userId: number) => ['getOneInchTerms', userId],
};

export const useGetOneInchNetworksQuery = (
  options: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getOneInchNetworks>>,
      unknown,
      unknown,
      any
    >,
    'enabled'
  > = {},
) => {
  return useQuery({
    queryKey: oneInchQueryKeys.getNetworks,
    queryFn: () => getOneInchNetworks(),
    ...options,
  });
};

export const prefetchOneInchNetworks = async (queryClient: QueryClient) => {
  await queryClient.prefetchQuery({
    queryKey: oneInchQueryKeys.getNetworks,
    queryFn: () => getOneInchNetworks(),
  });

  return queryClient.getQueryData<ReturnType<typeof getOneInchNetworks>>(
    oneInchQueryKeys.getNetworks,
  );
};

export const useGetOneInchTokensQuery = ({
  networkId,
  options,
}: {
  networkId?: Parameters<typeof getOneInchTokens>[0];
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getOneInchTokens>>,
      unknown,
      unknown,
      any
    >,
    'enabled' | 'placeholderData'
  >;
}) => {
  return useQuery({
    queryKey: oneInchQueryKeys.getTokens(networkId),
    queryFn: () => (networkId ? getOneInchTokens(networkId) : []),
    gcTime: 0,
    staleTime: Infinity,
    ...options,
  });
};

export const useGetOneInchSingleChainQuoteQuery = ({
  apiOptions,
  options,
}: {
  apiOptions?: Parameters<typeof getOneInchSingleChainQuote>[0];
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getOneInchSingleChainQuote> | undefined>,
      unknown,
      unknown,
      any
    >,
    'enabled' | 'refetchInterval'
  >;
}) => {
  return useQuery({
    queryKey: oneInchQueryKeys.getQuote(apiOptions?.searchParams),
    queryFn: async ({ signal }) => {
      if (!apiOptions) {
        return undefined;
      }

      try {
        return await getOneInchSingleChainQuote(apiOptions, signal);
      } catch (error) {
        if (signal?.aborted) {
          return undefined;
        }

        throw error;
      }
    },
    gcTime: 0,
    staleTime: 0,
    ...options,
  });
};

export const prefetchOneInchTokens = async (networkId: number, queryClient: QueryClient) => {
  await queryClient.prefetchQuery({
    queryKey: oneInchQueryKeys.getTokens(networkId),
    queryFn: () => getOneInchTokens(networkId),
  });

  return queryClient.getQueryData<ReturnType<typeof getOneInchTokens>>(
    oneInchQueryKeys.getTokens(networkId),
  );
};

interface UseGetOneInchQuoteQueryParams {
  fromNetworkId?: OneInchNetwork['id'];
  toNetworkId?: OneInchNetwork['id'];
  fromTokenAddress?: string;
  toTokenAddress?: string;
  amount?: string;
  walletAddress?: string;
}

export const useGetOneInchQuoteQuery = (
  params?: UseGetOneInchQuoteQueryParams,
  options?: Pick<
    UndefinedInitialDataOptions<Awaited<OneInchQuote | undefined>, unknown, unknown, any>,
    'enabled' | 'refetchInterval'
  >,
) => {
  return useQuery({
    queryKey: oneInchQueryKeys.getQuote(params),
    queryFn: async ({ signal }): Promise<OneInchQuote | undefined> => {
      if (!params) {
        return undefined;
      }

      const {
        fromNetworkId,
        toNetworkId,
        fromTokenAddress,
        toTokenAddress,
        amount,
        walletAddress,
      } = params;

      if (!(fromNetworkId && toNetworkId && fromTokenAddress && toTokenAddress)) {
        return undefined;
      }

      if (!amount || BigNumber.from(amount).isZero()) {
        return {
          dstAmount: '0',
        };
      }

      try {
        if (fromTokenAddress.toLowerCase() === toTokenAddress.toLowerCase()) {
          return {
            dstAmount: amount,
          };
        }

        if (fromNetworkId === toNetworkId) {
          const { data } = await getOneInchSingleChainQuote(
            {
              searchParams: {
                networkId: fromNetworkId,
                fromTokenAddress,
                toTokenAddress,
                amount: amount.toString(),
              },
            },
            signal,
          );

          return {
            dstAmount: data.toAmount,
            gas: data.gas,
          };
        } else {
          if (!walletAddress) {
            throw new Error('Sign in and connect a wallet to receive a quote for this swap');
          }

          const details = await getOneInchCrossChainQuote(
            {
              searchParams: {
                srcChain: fromNetworkId,
                dstChain: toNetworkId,
                srcTokenAddress: fromTokenAddress,
                dstTokenAddress: toTokenAddress,
                amount: amount.toString(),
                walletAddress,
              },
            },
            signal,
          );

          const result = {
            dstAmount: details.dstTokenAmount,
            details: {
              quoteId: details.quoteId,
              srcTokenAmount: details.srcTokenAmount,
              dstTokenAmount: details.dstTokenAmount,
              presets: details.presets,
              timeLocks: details.timeLocks,
              srcEscrowFactory: details.srcEscrowFactory,
              dstEscrowFactory: details.dstEscrowFactory,
              srcSafetyDeposit: details.srcSafetyDeposit,
              dstSafetyDeposit: details.dstSafetyDeposit,
              whitelist: details.whitelist,
              recommendedPreset: details.recommendedPreset,
              prices: details.prices,
              volume: details.volume,
              priceImpactPercent: details.priceImpactPercent,
              mode: 'cross-chain',
            } satisfies OneInchCrossChainQuote & { mode: 'cross-chain' },
          };

          return result;
        }
      } catch (error) {
        if (signal?.aborted) {
          return undefined;
        }

        throw error;
      }
    },
    gcTime: 0,
    staleTime: 0,
    ...options,
  });
};

export const useGetOneInchTokenAllowanceQuery = ({
  networkId,
  apiOptions,
  options,
}: {
  networkId?: Parameters<typeof getOneInchTokenAllowance>[0];
  apiOptions?: Parameters<typeof getOneInchTokenAllowance>[1];
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getOneInchTokenAllowance>> | undefined,
      unknown,
      unknown,
      any
    >,
    'enabled'
  >;
}) => {
  return useQuery({
    queryKey: oneInchQueryKeys.getAllowance({
      networkId,
      tokenAddress: apiOptions?.searchParams.tokenAddress,
    }),
    queryFn: () =>
      networkId && apiOptions ? getOneInchTokenAllowance(networkId, apiOptions) : undefined,
    gcTime: 0,
    staleTime: 0,
    ...options,
  });
};

export const useIncreaseOneInchTokenAllowanceMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: oneInchQueryKeys.increaseAllowance,
    mutationFn: getOneInchTxApprove,
    onSuccess: (data, variables) => {
      console.log('useIncreaseOneInchTokenAllowanceMutation::onSuccess', data, variables);

      queryClient.invalidateQueries({
        queryKey: oneInchQueryKeys.getAllowance({
          networkId: variables.networkId,
          tokenAddress: variables.options.searchParams.tokenAddress,
        }),
      });
    },
  });
};

export const useSingleChainSwapTokensMutation = () => {
  return useMutation({
    mutationKey: oneInchQueryKeys.increaseAllowance,
    mutationFn: makeOneInchSingleChainTokensSwap,
  });
};

export const useGetTokenPriceInUSDQuery = ({
  networkId,
  tokenAddress,
  options,
}: {
  networkId?: Parameters<typeof getTokenPriceInUSD>[0];
  tokenAddress?: Parameters<typeof getTokenPriceInUSD>[1];
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getTokenPriceInUSD>> | undefined,
      unknown,
      unknown,
      any
    >,
    'enabled'
  >;
}) => {
  return useQuery({
    queryKey: oneInchQueryKeys.getTokenPriceInUSD(networkId, tokenAddress),
    queryFn: () =>
      networkId && tokenAddress ? getTokenPriceInUSD(networkId, tokenAddress) : undefined,
    gcTime: 30000,
    staleTime: 30000,
    retry: 3,
    ...options,
  });
};

export const useStoreSwapTxMutation = () => {
  return useMutation({ mutationKey: oneInchQueryKeys.storeSwapTx, mutationFn: storeSwapTx });
};

export const useGetOneInchGasPricesQuery = ({
  networkId,
  options,
}: {
  networkId?: Parameters<typeof getOneInchGasPrices>[0];
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getOneInchGasPrices>> | undefined,
      unknown,
      unknown,
      any
    >,
    'enabled'
  >;
} = {}) => {
  return useQuery({
    queryKey: oneInchQueryKeys.getOneInchGasPrices,
    queryFn: () => (networkId ? getOneInchGasPrices(networkId) : undefined),
    gcTime: 0,
    staleTime: 0,
    retry: 3,
    ...options,
  });
};

export const prefetchOneInchTermsQuery = async (
  clientQuery: QueryClient,
  userId: number,
  options: Options,
) => {
  const key = oneInchQueryKeys.getTerms(userId);

  await clientQuery.prefetchQuery({ queryKey: key, queryFn: () => getOneInchTerms(options) });

  return clientQuery.getQueryData<ReturnType<typeof getOneInchTerms>>(key);
};

export const useGetOneInchTermsQuery = (
  userId: number,
  options?: Pick<
    UndefinedInitialDataOptions<Awaited<ReturnType<typeof getOneInchTerms>>, unknown, unknown, any>,
    'enabled'
  >,
) => {
  return useQuery({
    queryKey: oneInchQueryKeys.getTerms(userId),
    queryFn: () => getOneInchTerms(),
    gcTime: 0,
    staleTime: 0,
    ...options,
  });
};
